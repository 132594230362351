import {useEffect, useRef, useState} from "react";
import axios from "axios";

export const Logout = () => {

    const logoutAttempted = useRef(false);

    useEffect(() => {
        if (!logoutAttempted.current) {
            (async () => {
                try {
                    const {data} = await axios.post('https://gen-ai-6onvxnvwqa-ue.a.run.app/user/logout/', {
                        refresh_token: localStorage.getItem('refresh_token')
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                        }
                    }, {withCredentials: true});
                    localStorage.clear();
                    axios.defaults.headers.common['Authorization'] = null;
                    window.location.href = '/login'
                } catch (e) {
                    console.log(e)
                    console.log('logout not working')
                }
                logoutAttempted.current = true
            })();
        }
    }, []);

    return (
        <div></div>
    )
}
