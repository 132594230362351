import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {Login} from "./component/login";
import {Navigation} from './component/navigation';
import {Logout} from './component/logout';
import  {Home} from "./component/Home";
import {ProtectedRoute} from './component/ProtectedRoute';

function App() {
    return <BrowserRouter>
    <Navigation></Navigation>
        <Routes>
            <Route path="/" element={
                <ProtectedRoute element={Home} />
            }/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/logout" element={<Logout/>}/>
        </Routes>
    </BrowserRouter>;
}

export default App;
