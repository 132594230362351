import React, { useState } from 'react';
import axios from 'axios';
import './SearchBar.css'; // Make sure the path to your CSS file is correct

function SearchBar({ onSearch }) {
  const [input, setInput] = useState('');

  const handleSearch = async () => {
    if (input.trim()) {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.post('https://gen-ai-6onvxnvwqa-ue.a.run.app/api/search/', { query: input }, {
          headers: {
            'Authorization': `Bearer ${token}`,  // Assuming 'token' contains your JWT
            'Content-Type': 'application/json'
          }
        });

        // Assuming the actual result is under response.data.response
        onSearch(response.data.response);  // Adjusted to pass the actual survey response text
        setInput(''); // Clear the input after search
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    }
  };


  return (
    <div className="search-bar">
      <input
        type="text"
        className="search-input"
        value={input}
        onChange={e => setInput(e.target.value)}
        onKeyPress={e => e.key === 'Enter' && handleSearch()}
        placeholder="Ask Prompta AI..."
      />
      <button className="search-button" onClick={handleSearch}>Insights</button>
    </div>
  );
}

export default SearchBar;
