import {useEffect, useState} from "react";
import axios from "axios";
import {Navigate} from "react-router-dom";
import SearchBar from './SearchBar';

export const Home = () => {
    useEffect(() => {
        if(localStorage.getItem('access_token') === null) {
            window.location.href = '/login'
        }
    }, []);

    const [searchResult, setSearchResult] = useState("");  // Simplified for direct use as string

      const handleSearchResults = result => {
        console.log('Search result:', result); // Log result to debug
        setSearchResult(result); // Assuming result is a string
      };

    return <div className="form-signin mt-5">
        <h1 style={{textAlign: 'center', color: 'darkblue'}}>Pairing Data + Human Connection</h1>
        <SearchBar onSearch={handleSearchResults} />
        <div>
            <p style={{marginLeft: '20px', marginRight: '20px'}}>Reasoning: {searchResult}</p>
        </div>
    </div>
}
