import React, { useState, useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

export const ProtectedRoute = ({ element: Component, ...rest }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const verificationAttempted = useRef(false);

    useEffect(() => {
        if (!verificationAttempted.current) {
            const token = localStorage.getItem('access_token');
            if (!token) {
                console.log("No token found. Redirecting to login.");
                setIsAuthenticated(false);
                setIsLoading(false);
                verificationAttempted.current = true;
                return;
            }

            axios.post('https://gen-ai-6onvxnvwqa-ue.a.run.app/token/verify/', { token: token }, {
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {
                console.log("Token verification successful");
                setIsAuthenticated(true);
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Authentication verification failed:", error);
                localStorage.clear();
                setIsAuthenticated(false);
                setIsLoading(false);
            });
            verificationAttempted.current = true;
        }
    }, []); // Empty dependency array to run only on mount

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" replace />;
};
